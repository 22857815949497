import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import OrgTableItem from "@/entity/system/OrgTableItem";
import API from "@/api/system/org";
import OrgTypeAPI from "@/api/system/master/org-type";
import CityAPI from "@/api/system/master/city";

@Options({
    name: "app-system-org",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Org extends BaseTableMixins<OrgTableItem> implements Partial<BaseTableInterface<OrgTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new OrgTableItem();
        this.editModalItem = new OrgTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgTypeId: {
                type: "eq",
                name: "org_type_id",
                title: "组织类型",
                parent: null,
                value: null,
                openFn: this.orgTypeIdLevelModalOpen,
            },
            orgParentCode: {
                type: "router",
                name: "org_code",
                title: "上级组织",
                parent: this.authUser.orgParentCode,
                value: null,
                openFn: this.orgParentCodeLevelModalOpen,
            },
            adcode: {
                type: "router",
                name: "adcode",
                title: "所属地区",
                parent: -1,
                value: null,
                openFn: this.adcodeLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //组织类型API
    get getOrgTypeAPI(): any {
        return OrgTypeAPI;
    }

    //地区API
    get getCityAPI(): any {
        return CityAPI;
    }

    // //列表回调
    // public listCallback(items: Array<OrgTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new OrgTableItem();
        this.editModalItem = new OrgTableItem();
        this.editModalItem.orgStatus = 1;
        this.editModalTitle = "添加组织";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgName)) return "组织名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgStatus)) return "组织状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "组织类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgParentCode)) return "上级组织不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "组织代码不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改组织 (" + this.editModalItem.orgName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "组织ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除组织",
            message: "组织名称: " + this.editModalItem.orgName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "组织ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "组织状态",
            message: "组织名称: " + this.editModalItem.orgName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.orgStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.orgStatus = this.editModalItem.orgStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.orgCode)) return "组织ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "组织排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (this.editModalItem.orgParentCode < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        return null;
    }

    //orgTypeId搜索字段
    private orgTypeIdLevelSearchField: any = [
        { field: "org_type_name", name: "名称" },
        { field: "org_type_remark", name: "说明" },
    ];
    //orgTypeId层级选择器打开
    public orgTypeIdLevelModalOpen() {
        (this.$refs.orgTypeIdLevel as any).openModal();
    }
    //orgTypeId层级选择器提交
    public async orgTypeIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgTypeId : item;
        this.tableRequestItem.lockItem.orgTypeId.value = curItem.value;
        this.tableRequestItem.lockItem.orgTypeId.title = curItem.title;
        this.tableRequestItem.lockItem.orgTypeId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //orgParentCode搜索字段
    private orgParentCodeLevelSearchField: any = [
        { field: "org_code", name: "代码" },
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgParentCode层级选择器打开
    public orgParentCodeLevelModalOpen() {
        (this.$refs.orgParentCodeLevel as any).openModal();
    }
    //orgParentCode层级选择器提交
    public async orgParentCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgParentCode : item;
        this.tableRequestItem.lockItem.orgParentCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgParentCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgParentCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //adcode搜索字段
    private adcodeLevelSearchField: any = [
        { field: "adcode", name: "代码" },
        { field: "name", name: "名称" },
    ];
    //adcode层级选择器打开
    public adcodeLevelModalOpen() {
        (this.$refs.adcodeLevel as any).openModal();
    }
    //adcode层级选择器提交
    public async adcodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().adcode : item;
        this.tableRequestItem.lockItem.adcode.value = curItem.value;
        this.tableRequestItem.lockItem.adcode.title = curItem.title;
        this.tableRequestItem.lockItem.adcode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get orgParentCodeSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "org_parent_code",
            status: "org_status",
            name: "org_name",
            id: "org_code",
            cid: this.editModalItem.orgParentCode,
            mid: this.editModalItem.orgCode,
            extend: true,
        };
    }

    get orgTypeIdSelectApi() {
        return {
            api: (query: any) => OrgTypeAPI.idSelect(query),
            status: "org_type_status",
            name: "org_type_name",
            id: "org_type_id",
            cid: this.editModalItem.orgTypeId,
        };
    }

    get adcodeSelectApi() {
        return {
            api: (query: any) => CityAPI.idSelect(query),
            parent: "parent_adcode",
            status: "status",
            name: "name",
            id: "adcode",
            cid: this.editModalItem.adcode,
        };
    }
}
